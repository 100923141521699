<template>
  <div class="container">
    <nav class="breadcrumb is-large" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link
            :to="{ name: 'negotiatorStart', params: { id: $route.params.id } }"
          >
            <span>Start</span></router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              name: 'negotiatorPayLess',
              params: { id: $route.params.id }
            }"
          >
            <span>Pay Less</span></router-link
          >
        </li>
        <li class="is-active">
          <router-link
            :to="{
              name: 'negotiatorPayLessFail',
              params: { id: $route.params.id }
            }"
          >
            <span>Offer</span></router-link
          >
        </li>
      </ul>
    </nav>
    <div class="content">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">
            <span>{{ instanceConfig.offerDeclinedTitle }}</span>
          </p>
        </header>
        <div class="card-content">
          <p class="content">
            {{ negotiatorSOD2Results["catch-all"].message }}
          </p>
        </div>
      </div>
    </div>
    <div class="field">
      <div class="buttons">
        <router-link
          tag="button"
          class="button is-success"
          :to="{
            name: 'paymentForm',
            params: { id: $route.params.id },
            query: {
              action: 'negotiator',
              needsSD: false,
              sdAccepted: false
            }
          }"
          >Accept</router-link
        >
        <button class="button">Pay Over Time</button>
        <router-link
          :to="{ name: 'makePayment', params: { id: $route.params.id } }"
          tag="button"
          class="button"
          >Cancel</router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapState(["instanceConfig", "negotiatorSOD2Results"]),
    ...mapGetters(["getRetVal"])
  },
  methods: {
    ...mapActions([
      "fetchPaymentPortalInstanceConfig",
      "fetchUserInfo",
      "fetchMessages"
    ]),
    ...mapActions(["accounts", ["fetchAccounts"]])
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      await vm.fetchPaymentPortalInstanceConfig(vm.$route.params.instanceId);
      if (
        vm.$store.state.userInfo == null ||
        vm.$store.state.userInfo.firstName == null
      ) {
        await vm.fetchUserInfo();
      }
      if (
        vm.$store.state.accounts.accounts == null ||
        vm.$store.state.accounts.accounts.length == 0
      ) {
        await vm.fetchAccounts();
      }
    });
  }
};
</script>
